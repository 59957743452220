import React from 'react';
import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import ChallengesIcon from '../../images/hackathon.jpg';
import B1 from '../../images/new/chal_fct1.jpg';
import B2 from '../../images/new/chal_fct2.jpg';
import B3 from '../../images/new/chal_fct3.jpg';

const ChallengesPage = () => (
  <Layout>
    <Feature
      previous="/features/academy"
      next="/features/openinno"
      color={theme.palette.secondary.main}
      title="Challenges"
      icon={ChallengesIcon}
      subtitle="Vos collaborateurs ont forcément la solution à vos problématiques."
      description="Lancer des challenges auprès de tous vos collaborateurs pour répondre à des problématiques, quelqu’un au sein de votre organisation a très souvent la solution. Ne réinventez plus la roue et capitalisez sur l’expertise et les compétences de vos collaborateurs."
      subSections={[
        'Trouver rapidement des solutions',
        'Stimuler la créativité',
        'Favoriser l’esprit d’entre-aide',
      ]}
      sections={[
        {
          title: 'Création de challenges',
          description:
            'L’entreprise ou chacun de vos collaborateurs crée facilement des challenges publics ou privés.',
          image: B1,
        },
        {
          title: 'Participation simplifiée',
          description:
            'Les participants envoient facilement leurs contributions et votent depuis leur smartphone ou ordinateur.',
          image: B2,
        },
        {
          title: 'Gestion optimisée',
          description:
            'Le créateur visualise rapidement les contributions au challenge et désigne le ou les vainqueurs.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default ChallengesPage;
